.input {
    border-color: #979bb5;
    border-width: 1px;
    border-radius: 16px;
    padding: 10px;
    padding-left: 30px;
    height: 60px;
    width: 80%;
    margin-top: 6px;
    font-family: "regular";
    font-size: 0.95em;
    color: #818194;
  }
  
  .error {
    border-color: red;
  }
  