@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  body {
    width: 100vw;
    height: 100vh;
  }

  div#root {
    width: 100%;
    height: 100%;
  }

  header {
    @apply flex;
    @apply flex-col;
    @apply justify-between;
    @apply items-center;
    background: url("./assets/yaldo_icon_main_color.svg") no-repeat fixed -10% -10%,
      linear-gradient(90deg, rgba(94, 36, 182, 1) 0%, rgba(24, 8, 94, 1) 100%);
  }

  section {
    scroll-behavior: smooth;
  }

  .gradient {
    background: url("./assets/yaldo_icon_main_color.svg") no-repeat fixed -10% -10%,
      linear-gradient(90deg, rgba(94, 36, 182, 1) 0%, rgba(24, 8, 94, 1) 100%);
  }
}

@font-face {
  font-family: "regular";
  src: url("./assets/fonts/SourceSansPro-Regular.ttf");
}

@font-face {
  font-family: "bold";
  src: url("./assets/fonts/SourceSansPro-Bold.ttf");
}

@font-face {
  font-family: "semiBold";
  src: url("./assets/fonts/SourceSansPro-SemiBold.ttf");
}

h1 {
    @apply text-3xl;
    @apply font-bold;
    @apply h-fit;
    font-family: "bold";
}


h2 {
  @apply text-2xl;
  @apply m-3;
  @apply font-bold;
  @apply h-fit;
  font-family: "regular";
}

h3 {
  @apply text-xl;
  @apply m-3;
  @apply font-bold;
  @apply h-fit;
  font-family: "regular";
}

h4 {
  @apply text-lg;
  @apply mb-3;
  @apply font-bold;
  @apply h-fit;
  font-family: "regular";
}

p {
  @apply m-4;
  @apply h-fit;
  font-family: "regular";
}

b {
  font-family: "bold";
  font-size: 28px;
}


.selected {
  transform: scale(1.03);
  border: 2px solid #19005E !important;
}

@media (min-width: 3840px) {
  /* Styles for 4K screens */
  input {
    font-size: 1.2em !important; 
    padding: 8px !important; 
  }
}

.hoverable-button:hover {
  animation: scaleButton 0.3s ease forwards;
}
@keyframes scaleButton {
  0% { transform: scale(1); }
  100% { transform: scale(1.03); }
}

th:first-of-type {
  border-top-left-radius: 20px;
  border: none !important;
}
th:last-of-type {
  border-top-right-radius: 20px;
  border: none !important;
}

th {
  border-top: none !important;
  border-bottom: none !important;
}

/* tr:last-child td:first-of-type.dlinfo2 { 
  border-radius: 0 0 0 20px !important;
 }
 */

/*  tr:last-child td:last-of-type.dlinfo2 { 
  border-radius: 0 0 20px 0 !important;
  background: #19005E !important;
  border: none !important;
  border-style: none !important;
  border-spacing: 0px;
 } */

 table.dltrc {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px;
  border: solid #C8C3D9 2px;
  border-radius: 20px;
}

tr.dlheader {
  text-align: center;
  font-weight: bold;
  border-left: solid #C8C3D9 1px;
  padding: 2px;

}

td.dlheader {
  background: #19005E;
  color: white;
  font-family: 'regular';
  text-align: center;
  font-weight: bold;
  border-left: solid #C8C3D9 1px;
  border-radius: 0px;
  padding: 2px;
}

tr.dlinfo,
td.dlinfo {
  text-align: center;
  border-left: solid #C8C3D9 1px;
  border-top: solid #C8C3D9 1px;
  padding: 2px
}

td.dlinfo:first-child,
td.dlheader:first-child {
  border-left: none;
}

td.dlheader:first-child {
  border-radius: 20px 0 0 0;
}

td.dlheader:last-child {
  border-radius: 0 20px 0 0;
}

/* .bookingCard{
  opacity: 1;
  -webkit-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.2);
  box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.2);
} */


/* .bookingCard:hover{
  animation: scaleButton 0.3s ease forwards;
} */

.bookingImg{
  object-fit: fill;
  max-height: 120px;
  width: 100%;
  border-radius: 20px;
}



#itemsTd {
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent content from wrapping to the next line */
}

#itemsTd::-webkit-scrollbar {
  top: 0; /* Position the scrollbar at the top */
  bottom: initial; /* Reset bottom positioning */
}


.event_overflow{
  background: rgb(55, 31, 135);
  opacity: 50%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px; 
}


/**/

.md-stepper-horizontal {
	display:table;
	width:90%;
	margin:0 auto;
	background-color:#FFFFFF;
}
.md-stepper-horizontal .md-step {
	display:table-cell;
	position:relative;
	padding:24px;
}

.md-stepper-horizontal .md-step:active {
	border-radius: 15% / 75%;
}
.md-stepper-horizontal .md-step:first-child:active {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.md-stepper-horizontal .md-step:last-child:active {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.md-stepper-horizontal .md-step:first-child .md-step-bar-left,
.md-stepper-horizontal .md-step:last-child .md-step-bar-right {
	display:none;
}
.md-stepper-horizontal .md-step .md-step-circle {
	width:30px;
	height:30px;
	margin:0 auto;
	border-radius: 50%;
  border: 2px solid #E6E4EE;

	text-align: center;
	line-height:30px;
	font-size: 16px;
	font-weight: 600;
	color:#E6E4EE;
}

.md-stepper-horizontal .md-step.active .md-step-circle {
	background-color: #ffffff;
  border: 2px solid #19005E;
  color: #19005E;
}

.md-stepper-horizontal .md-step.done .md-step-circle {
	background-color: #19005E;
  border: 2px solid #19005E;
  color: #ffffff;
}


.md-stepper-horizontal .md-step .md-step-title {
	margin-top:16px;
	font-size:16px;
	font-weight:600;
}
.md-stepper-horizontal .md-step .md-step-title,
.md-stepper-horizontal .md-step .md-step-optional {
	text-align: center;
	color:rgba(0,0,0,.26);
}
.md-stepper-horizontal .md-step.active .md-step-title {
	font-weight: 600;
	color:#19005E;
}

.md-stepper-horizontal .md-step .md-step-bar-left,
.md-stepper-horizontal .md-step .md-step-bar-right {
	position:absolute;
	top:36px;
	height:1px;
	border-top:2px solid #DDDDDD;
}

.md-stepper-horizontal .md-step .md-step-bar-right.done,
.md-stepper-horizontal .md-step .md-step-bar-left.done 
 {
	position:absolute;
	top:36px;
	height:1px;
	border-top:2px solid #19005E;
}

.md-stepper-horizontal .md-step .md-step-bar-right {
	right:0;
	left:50%;
	margin-left:20px;
}
.md-stepper-horizontal .md-step .md-step-bar-left {
	left:0;
	right:50%;
	margin-right:20px;
}



.dialog {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease-in-out;
}

.dialog.open {
  display: flex;
}

.dialog-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}
